

import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../interfaces/content.interface'

export const activityTypeInDepartment: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: 'Les {pluralActivityType} {atDepartment}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{department}</span>',
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: '{allWithGender} les {pluralActivityType} {atDepartment}{department}',
        description: 'Trouvez des projets ou {pluralActivityType} {organizationsWithGender} participer {atDepartment}{department}, et rejoignez des centaines de clubs, amicales, compagnies référencés sur la plateforme HelloAsso.',
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}',
            name: '{region}'
          },
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}/dep/{seoDepartment}',
            name: '{department}'
          },
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}/dep/{seoDepartment}/act/{seoActivityType}',
            name: '{pluralActivityType}'
          }
        ],
      }
    }
  },
  // Les <type d'activité> en <départment>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'activitiesInDepartment',
    text: {
      title: 'Les {pluralActivityType} {atDepartment}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{department}</span>',
      subtitle: 'Découvrez toutes les activités et animations qui ont lieu en ce moment',
      showAllQueryParams: '?tab=activites&activity_type={seoActivityType}&place_region={region}&place_department={department}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ACTIVITIES,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous êtes une association {ofActivityType}{toLowerActivityType} {fromDepartment}<span class="Color-Primary">{department}</span> ?'
    },
    slug: 'activity_type'
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  },
  // Sitemap
  {
    component: BLOCK_COMPONENT.SITEMAP,
    showCities: true
  },
  // HelloAsso, comment ça marche ?
  {
    component: BLOCK_COMPONENT.HOW_IT_WORKS
  }
]
