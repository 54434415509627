
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../interfaces/content.interface'

export const categoryInRegion: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: 'Les associations {ofCategory}{toLowerCategory} {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>'
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: 'Annuaire des Associations dans la catégorie {toLowerCategory} {atRegion}{region}',
        description: 'Trouvez des projets ou activités associatives auxquels participer dans la catégorie {toLowerCategory} {atRegion}{region}, et rejoignez des centaines de clubs, amicales, compagnies référencés sur la plateforme HelloAsso.'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}',
            name: '{region}'
          },
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}/cat/{seoCategory}',
            name: '{toLowerCategory}'
          }
        ],
        parentCategory: {
          url:
            'https://helloasso.com/e/reg/{seoRegion}/cat/{seoParentCategory}',
          name: '{toLowerParentCategory}'
        },
      }
    }
  },
  // Les activités de <catégorie> en <région>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'activitiesOfCategoryInRegion',
    text: {
      title: 'Agenda : les activités {ofCategory}{toLowerCategory} {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>',
      subtitle: 'Découvrez toutes les activités et animations qui ont lieu en ce moment',
      showAllQueryParams: '?tab=activites&category_tags={seoCategory}&place_region={region}'
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ACTIVITIES,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Associations de <catégorie> en <région>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    id: 'organizationsOfCategoryInRegion',
    text: {
      title: 'Annuaire : les associations {ofCategory}{toLowerCategory} {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>',
      subtitle: 'Bénévolat, don, engagement rejoignez des centaines de clubs, amicales, compagnies',
      showAllQueryParams: '?tab=associations&category_tags={seoCategory}&place_region={region}'
    },
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous êtes une association {ofCategory}{toLowerCategory} {fromRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span> ?',
    },
    slug: 'category_tags'
  },
  // Projets de <catégorie> en <région>
  {
    component: BLOCK_COMPONENT.CAROUSEL_GROUP,
    // SectionTitle,
    id: 'projectsOfCategoryInRegion',
    slug: 'category_tags',
    text: {
      title: 'Les projets de financement participatifs {ofCategory}{toLowerCategory} {atRegion}<span class="Highlighted Highlighted-MintTransparent Color-Primary">{region}</span>',
      subtitle: 'Vous souhaitez vous engager pour une cause ? Voici ce qu’il se passe en ce moment.'
    },
    // Carousel
    carousels: [
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'Projets de financement participatif {atRegion}{region}',
          showAllQueryParams: '?tab=projets&category_tags={seoCategory}&place_region={region}'
        },
        id: 'allProjectsOfInRegion',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.PROJECTS,
          params: {
            hitsPerPage: 50,
            filters: {},
          }
        }
      }
    ]
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  },
  // Sitemap
  {
    component: BLOCK_COMPONENT.SITEMAP,
    showDepartments: true
  },
  // HelloAsso, comment ça marche ?
  {
    component: BLOCK_COMPONENT.HOW_IT_WORKS
  }
]
