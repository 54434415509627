
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'

export const seismes: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: '<span class="Color-Primary">{haTag}</span>',
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: "{titre}",
        description: 'Description'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/evt/{seoHaTag}',
            name: '{haTag}'
          }
        ],
      }
    }
  },
  // Projets de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'projectsForTag',
    text: {
      title: 'Faire un don à une association qui apporte son aide aux <span class="Color-Primary">victimes des tremblements de terre</span>',
      subtitle: 'Des milliers de maisons ont été détruites dans le nord-ouest de la Syrie. Les besoins sont très importants : distribution de nourriture, livraison de matériel médical, accueil des réfugiés… Chacun d’entre nous peut aider les rescapés des séismes. Ces associations ont besoin de votre solidarité pour intervenir auprès des populations locales.',
      showAllQueryParams: '?tab=projets&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    itemNumber: 19,
    searchable: {
      indexName: SEARCH_ALL_INDEX.PROJECTS,
      params: {
        hitsPerPage: 19,
        filters: {}
      }
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Associations de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'organizationsForTag',
    text: {
      title: 'Les associations qui apportent leur aide aux <span class="Color-Primary">rescapés des séismes</span> du 6 février 2023 et qui ont besoin de votre soutien',
      subtitle: 'Petite ou grande, chacune de ces associations mobilise ses bénévoles pour soutenir les victimes en Turquie et en Syrie. Vous pouvez les aider depuis la France en faisant un don pour contribuer à leurs actions.',
      showAllQueryParams: '?tab=associations&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    itemNumber: 14,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 14,
        filters: {}
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: '<span class="Color-Primary">Votre association se mobilise face à la situation d’urgence ?</span>',
      subtitle: 'Gérez, diffusez et financez <span class="Color-Primary">gratuitement</span> vos activités.'
    }
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  }
]
