import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { FILTER_DATE_OPTIONS_KEY } from '~/domains/search/interfaces/filters.interface'

import { BLOCK_COMPONENT, type ContentPage } from '../interfaces/content.interface'

export const activityType: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: 'Les <span class="Highlighted Highlighted-MintTransparent Color-Primary">{pluralActivityType}</span>',
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: '{allWithGender} les {pluralActivityType}',
        description: 'Trouvez des projets ou {pluralActivityType} {organizationsWithGender} participer proche de chez vous, et rejoignez des centaines de clubs, amicales, compagnies référencés sur la plateforme HelloAsso.',
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/act/{seoActivityType}',
            name: '{pluralActivityType}'
          }
        ],
      }
    }
  },
  // Les <type d'activité>
  // urls parameters for this page are meant to be:
  // - activity_type
  {
    component: BLOCK_COMPONENT.CAROUSEL_GROUP,
    id: 'activitiesToDo',
    slug: 'activity_type',
    text: {
      title: 'Les <span class="Highlighted Highlighted-MintTransparent Color-Primary">{pluralActivityType}</span>',
      subtitle: 'Découvrez toutes les activités et animations qui ont lieu en ce moment'
    },
    carousels: [
      // Les <type d'activité> cette semaine
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'Tous les {pluralActivityType}',
          showAllQueryParams: '?tab=activites&activity_type={seoActivityType}&date=current_week'
        },
        id: 'activitiesToDoThisWeek',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.CURRENT_WEEK
            },
          }
        }
      },
      // Les <type d'activité> la semaine prochaine
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'les {pluralActivityType} la semaine prochaine',
          showAllQueryParams: '?tab=activites&activity_type={seoActivityType}&date=la+semaine+prochaine'
        },
        id: 'activitiesToDoNextWeek',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.NEXT_WEEK
            },
          }
        }
      },
      // Les <type d'activité> le mois prochain
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'Les {pluralActivityType} le mois prochain',
          showAllQueryParams: '?tab=activites&activity_type={seoActivityType}&date=le+mois+prochain'
        },
        id: 'activitiesToDoNextMonth',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.NEXT_MONTH
            },
          }
        }
      },
      // Toutes les activités
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'Toutes les activités liées {toActivityTypes}',
          showAllQueryParams: '?tab=activites&activity_type={seoActivityType}'
        },
        id: 'allActivities',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {},
          }
        }
      }
    ]
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous êtes une association qui organise des <span class="Color-Primary">{pluralActivityType}</span> ?'
    }
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ,
  },
  // Sitemap
  {
    component: BLOCK_COMPONENT.SITEMAP
  },
  // HelloAsso, comment ça marche ?
  {
    component: BLOCK_COMPONENT.HOW_IT_WORKS
  }
]
