
export enum PageType {
  ACTIVITY_TYPE = 'activityType',
  ACTIVITY_TYPE_IN_CITY = 'activityTypeInCity',
  ACTIVITY_TYPE_IN_DEPARTMENT = 'activityTypeInDepartment',
  ACTIVITY_TYPE_IN_REGION = 'activityTypeInRegion',
  CATEGORY = 'category',
  CATEGORY_IN_CITY = 'categoryInCity',
  CATEGORY_IN_DEPARTMENT = 'categoryInDepartment',
  CATEGORY_IN_REGION = 'categoryInRegion',
  CITY = 'city',
  DEPARTMENT = 'department',
  REGION = 'region',
  HA_TAG = 'haTag'
}