
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'

export const aveclespompiers: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: '<span class="Highlighted Highlighted-MintTransparent Color-Primary">#UrgenceIncendies</span>'
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: '#UrgenceIncendies : participez à l’élan de générosité',
        description: 'Soutenez l’action des pompiers qui luttent contre les flammes et les associations qui se mobilisent'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/evt/aveclespompiers',
            name: 'Solidaires #AvecLesPompiers qui ont besoin de nous !'
          }
        ]
      }
    }
  },
  // Campagnes de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'projectsForFirefighters',
    text: {
      title: 'Les initiatives qui ont besoin de vous face à <span class="Highlighted Highlighted-MintTransparent Color-Primary">l’#UrgenceIncendies</span>',
      subtitle: 'Vos dons peuvent faire la différence pour concrétiser ces projets qui interviennent dans les zones sinistrées, visent à protéger l’environnement et la population, ou viennent en aide aux pompiers sur le terrain.',
      showAllQueryParams: '?tab=projets&ha_tags={seoHaTag}',
    },
    // Carousel
    // TODO might use hitsPerPage directly (more efficient) but would require to revalidate all thematic pages -> not right now
    itemNumber: 7,
    searchable: {
      indexName: SEARCH_ALL_INDEX.PROJECTS,
      params: {
        hitsPerPage: 7,
        filters: {
          ha_tags: 'aveclespompiers'
        },
      },
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Votre association se mobilise face à la situation d’urgence ?',
      customSubtitle: '<span>Inscrivez-vous et diffusez votre collecte sur cette page.</span><br/><span>Si vous êtes déjà inscrit sur HelloAsso, <a href="mailto:bienvenue@helloasso.org?subject=#UrgenceIncendies">contactez notre équipe</a> pour référencer votre collecte.</span>'
    },
    id: 'signUp'
  },
  // Associations de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'organizationsForFirefighters',
    text: {
      title: '<span class="Highlighted Highlighted-MintTransparent Color-Primary">Les organismes et associations de pompiers</span> que vous pouvez soutenir',
      subtitle: 'Découvrez les organismes qui se mobilisent dans l’urgence actuelle, et les amicales ou union départementales de sapeurs-pompiers qui sont au coeur de la vie des casernes. Soutenez les en faisant un don pour contribuer à l’élan de générosité.',
      showAllQueryParams: '?tab=associations&ha_tags={seoHaTag}',
    },
    // Carousel
    // TODO might use hitsPerPage directly (more efficient) but would require to revalidate all thematic pages -> not right now
    itemNumber: 38,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 38,
        filters: {
          ha_tags: 'aveclespompiers'
        }
      },
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  }
]
