
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'

export const octobreRose: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: '<span class="Color-Primary">{haTag}</span>',
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: "{titre}",
        description: 'Description'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/evt/{seoHaTag}',
            name: '{haTag}'
          }
        ],
      }
    }
  },
  // Les <type d'activité> pour <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'activitiesForTag',
    text: {
      title: 'Les activités de mobilisation pour <span class="Color-Primary">Octobre Rose</span>',
      subtitle: 'Découvrez toutes les activités et animations qui ont lieu pour cet événement',
      showAllQueryParams: '?tab=activites&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ACTIVITIES,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Projets de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'projectsForTag',
    text: {
      title: 'Les projets de soutien à <span class="Color-Primary">Octobre Rose</span>',
      subtitle: 'Vous souhaitez vous engager pour cette cause ? Voici ce qu’il se passe en ce moment.',
      showAllQueryParams: '?tab=projets&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.PROJECTS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Associations de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'organizationsForTag',
    text: {
      title: 'Les associations qui s\'engagent pour <span class="Color-Primary">Octobre Rose</span>',
      subtitle: 'Bénévolat, don, engagement, rejoignez le mouvement !',
      showAllQueryParams: '?tab=associations&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: '<span class="Color-Primary">Vous êtes une association qui se mobilise pour Octobre Rose ?</span>',
      subtitle: 'Gérez, diffusez et financez <span class="Color-Primary">gratuitement</span> vos activités.'
    }
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  }
]
