import type { HaMultipleQueriesQueryUI } from '~/domains/search/interfaces/query.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../interfaces/content.interface'
import { SEARCH_ALL_INDEX, type HASearchResponse } from '~/domains/search/interfaces/search.interface'
import type { Filters } from '~/domains/search/interfaces/filters.interface'

// searchables
export function findSearchableBlocks(page: ContentPage, mergeFilters: Filters = {}): HaMultipleQueriesQueryUI[] {

  const searchables = []

  for (const block of page) {
    if (block.component === BLOCK_COMPONENT.CAROUSEL_GROUP) {
      const group = findSearchableBlocks(block.carousels, mergeFilters)
      searchables.push(...group)
    }
    else if (
      block.component == BLOCK_COMPONENT.CAROUSEL
      || block.component == BLOCK_COMPONENT.HIT_LIST
      || block.component == BLOCK_COMPONENT.AMOUNT_CARD
    ) {
      if (!block.searchable?.indexName) {
        throw new Error('IndexName missing from searchable query')
      }
      const filters = {
        ...(block.searchable.params.filters || {}),
        ...(block.useUrlFilters ? mergeFilters : {})
      }
      const params = {
        ...(block.searchable.params || {}),
        filters
      }
      const query: HaMultipleQueriesQueryUI = {
        ...block.searchable,
        params
      }

      searchables.push(query)
    }
  }

  return searchables
}

export function mapResultsBlocks(
  page: ContentPage,
  results: HASearchResponse[],
  searchableIndex = 0
): {
  page: ContentPage,
  searchableIndex: number
} {
  for (const block of [...page]) {
    if (block.component === BLOCK_COMPONENT.CAROUSEL_GROUP) {
      const { page, searchableIndex: index } = mapResultsBlocks(block.carousels, results, searchableIndex)
      block.carousels = page
      searchableIndex = index
    }
    else if (
      block.component == BLOCK_COMPONENT.CAROUSEL
      || block.component == BLOCK_COMPONENT.HIT_LIST
      || block.component == BLOCK_COMPONENT.AMOUNT_CARD
    ) {
      if (results[searchableIndex]) {
        block.result = results[searchableIndex]
        searchableIndex++
      } else {
        throw new Error(`searchableIndex '${searchableIndex}' doest not exist in results list. Results have ${results.length} items`)
      }
    }
  }
  return { page, searchableIndex }
}