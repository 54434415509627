
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'
import { ACTIVITY_FORM_TYPE } from '~/domains/search/interfaces/filters.interface'

export const sudouest: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: "{titre}",
        description: 'Description'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/evt/{seoHaTag}',
            name: '{haTag}'
          }
        ],
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Campagnes de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'projects_CrowdFunding',
    text: {
      title: 'Collecte : les projets à financer dans la région Nouvelle-Aquitaine',
      subtitle: 'Vous souhaitez soutenir des projets pour la préservation de l’environnement face au réchauffement climatique dans la région Sud Ouest? Retrouvez ici les campagnes de financement participatif qui ont besoin de votre soutien !',
      showAllQueryParams: '?tab=projets&ha_tags={seoHaTag}',
    },
    searchable: {
      indexName: SEARCH_ALL_INDEX.PROJECTS,
      params: {
        hitsPerPage: 38,
        filters: {
          form_type: ACTIVITY_FORM_TYPE.CROWDFUNDING,
          ha_tags: 'sudouest'
        }
      }
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Campagnes de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'whatToDo',
    text: {
      title: 'Agenda : les événements sur l’environnement en Nouvelle-Aquitaine',
      subtitle: 'Tous les événements prévus et les activités proposées par les associations de la région en lien avec l’écologie et la préservation de l’environnement face au réchauffement climatique.',
      showAllQueryParams: '?tab=activities&ha_tags={seoHaTag}',
    },
    // Carousel
    // TODO might use hitsPerPage directly (more efficient) but would require to revalidate all thematic pages -> not right now
    itemNumber: 38,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ACTIVITIES,
      params: {
        hitsPerPage: 38,
        filters: {
          ha_tags: 'sudouest'
        }
      }
    }
  },
  // Associations de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'organizations',
    text: {
      title: 'Annuaire : les associations sur l’environnement',
      subtitle: 'Découvrez les associations de Nouvelle-Aquitaine engagées contre le réchauffement climatique. Don, bénévolat, participation aux événements … il n’y a pas qu’une seule façon de s’engager ! Retrouvez sur leurs pages le formulaire d’adhésion si vous souhaitez les rejoindre.',
      showAllQueryParams: '?tab=associations&ha_tags={seoHaTag}',
    },
    // Carousel
    // TODO might use hitsPerPage directly (more efficient) but would require to revalidate all thematic pages -> not right now
    itemNumber: 38,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 38,
        filters: {
          ha_tags: 'sudouest'
        }
      }
    }
  },
  // Campagnes de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'projects_Donation',
    text: {
      title: 'Collectes : les formulaires pour soutenir une association',
      subtitle: 'Vous souhaitez participer financièrement au fonctionnement d’une association ? Retrouvez ici toutes les campagnes de don des associations de la Région Nouvelle-Aquitaine qui œuvrent pour la sauvegarde de la région face au réchauffement climatique.',
      showAllQueryParams: '?tab=projets&ha_tags={seoHaTag}',
    },
    searchable: {
      indexName: SEARCH_ALL_INDEX.PROJECTS,
      params: {
        hitsPerPage: 38,
        filters: {
          form_type: ACTIVITY_FORM_TYPE.DONATION,
          ha_tags: 'sudouest'
        }
      }
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous faites partie d’une association ?',
      customSubtitle: '<span>Vous êtes membre d’une association en Nouvelle Aquitaine et vous souhaitez mettre en avant vos campagnes sur l’écologie ?</span>'
    },
    id: 'signUp'
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  }
]
