
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'

export const sapeursPompiers: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: '<span class="Color-Primary">{haTag}</span>'
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: 'Participez et soutenez les actions des pompiers ! HelloAsso',
        description: 'Les sapeurs-pompiers vous proposent de participer ou soutenir financièrement leurs actions : inscrivez-vous et faites-leur des dons en ligne sur HelloAsso.'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/evt/{seoHaTag}',
            name: '{haTag}'
          }
        ]
      }
    }
  },
  // Les <type d'activité> pour <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'activitiesForTag',
    text: {
      title: 'Participez à une activité organisée par une Amicale de <span class="Color-Primary">sapeurs-pompiers</span>',
      subtitle: 'Découvrez toutes les activités et animations organisées par des unions ou amicales de sapeurs-pompiers qui ont lieu en ce moment.',
      showAllQueryParams: '?tab=activites&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ACTIVITIES,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // Campagnes de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'projectsFirefighters',
    text: {
      title: 'Faites un don à une Amicale de <span class="Color-Primary">sapeurs-pompiers</span>',
      subtitle: 'Qu’il soit l\'heure de se mobiliser pour l’opération des calendriers des pompiers ou non : vous pouvez les soutenir financièrement ! Retrouvez ici tous les formulaires de dons des unions et amicales de sapeurs-pompiers.',
      showAllQueryParams: '?tab=projets&ha_tags={seoHaTag}',

    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.PROJECTS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Associations de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'organizationsFirefighters',
    text: {
      title: 'Les associations de <span class="Color-Primary">sapeurs-pompiers</span>',
      subtitle: 'Découvrez les amicales et unions de sapeurs-pompiers proches de chez vous et découvrez leurs actions.',
      showAllQueryParams: '?tab=associations&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous représentez une amicale ou une union <br> de <span class="Color-Primary">Sapeurs-Pompiers<span>&nbsp;?',
      customSubtitle:
        '<span>Gérez et diffusez <span class="Color-Primary">gratuitement</span> vos collectes et activités.</span>'
    },
    id: 'signUp'
  },
  {
    component: BLOCK_COMPONENT.FAQ
  }
]
