
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'

export const paques: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: '<span class="Color-Primary">{haTag}</span>',
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: "{titre}",
        description: 'Description'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/evt/{seoHaTag}',
            name: '{haTag}'
          }
        ],
      }
    }
  },
  // Les <type d'activité> pour <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'activitiesForTag',
    text: {
      title: '<span class="Color-Primary">Célébrez la fête de Pâques avec les activités associatives</span>',
      subtitle: 'Découvrez les événements les plus festifs de Pâques programmés par les associations de votre région et participez à l\'un d\'entre eux.',
      showAllQueryParams: '?tab=activites&ha_tags={seoHaTag}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ACTIVITIES,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: '<span class="Color-Primary">Votre association organise un événement pour Pâques&nbsp;?</span>'
    }
  }
]
